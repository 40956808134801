<template>
    <v-dialog v-model="active" width="600">
      <template v-slot:activator="{ on: dialogOn }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltipOn }">
            <v-btn icon v-on="{ ...dialogOn, ...tooltipOn }" :disabled="term===''">
              <v-icon>fal fa-toolbox</v-icon>
            </v-btn>
          </template>
          <span>Setup/Change Requirements</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>Setup or Change Requirements</v-card-title>
        <v-card-text>
          <v-text-field v-model="required" label="Credits Required" :disabled="!isLoaded" type="number" outlined></v-text-field>
          <p>There are {{ chapelCount }} 11:00 MWF chapels in this term.</p>
          <v-alert v-if="totalCount > 0" color="info">
            <template v-if="isProcessing">Processing. Updated {{ updatedCount }} out of {{ totalCount }} students.</template>
            <template v-else>Processing completed. All {{ totalCount }} students with {{ origRequired }} credits required have been changed to {{ required }}.</template>
          </v-alert>
        </v-card-text>
        <v-card-actions v-if="completed">
          <v-btn text @click="close(true)">Done</v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="!isProcessing">
          <v-btn text @click="close(false)">Cancel</v-btn>
          <v-btn text color="primary" @click="save" :disabled="required === origRequired">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import { ref, watch, onMounted } from '@vue/composition-api'

export default {
  props: {
    term: {
      type: String,
      default: ''
    }
  },
  setup (props, { root, emit }) {
    const active = ref(false)
    const required = ref('')
    const origRequired = ref('')
    const isLoaded = ref(false)
    const chapelCount = ref(0)

    watch(() => props.term, () => {
      if (props.term !== '') {
        isLoaded.value = false
        root.$feathers.service('chapel/student').find({ query: { term: props.term, $distinct: 'required' } }).catch((err) => {
          alert('Error loading requirements: ' + err)
        }).then(({ data }) => {
          let max = 0
          let requirements = 0
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              if (data[i].total > max) requirements = data[i]._id
            }
            origRequired.value = requirements
            required.value = requirements
            isLoaded.value = true
          } else {
            alert('No students found in the current term. Please make sure the students are loaded before you add the requirements.')
          }
        })

        root.$feathers.service('chapel/schedule').find({ query: { term: props.term, $limit: 100 } }).catch((err) => {
          alert('Error loading chapel count: ' + err)
        }).then(({ data }) => {
          let count = 0
          // Go through the list of chapels and find all of the ones that are MWF
          for (let i = 0; i < data.length; i++) {
            let dt = new Date(data[i].date)
            if (dt.getDay() % 2 === 1 && dt.getHours() === 11) count++
          }
          chapelCount.value = count
        })
      }
    })

    onMounted(() => {
      root.$feathers.service('chapel/schedule').find({ query: { $distinct: 'term' } }).catch((err) => {
        alert('Error counting chapels: ' + err)
      }).then(({ data }) => {
        let arr = []
        for (let i = 0; i < data.length; i++) {
          let text = data[i]._id
          let [ springOrFall, year ] = text.split(' ')
          if (springOrFall === 'Spring') arr.push({ value: year + '05', text })
          else arr.push({ value: year + '60', text })
        }
        arr.sort((a, b) => {
          let [ termA, yearA ] = a.text.split(' ')
          let [ termB, yearB ] = b.text.split(' ')
          if (termA === 'Spring') yearA += '05'
          else yearA += '60'
          if (termB === 'Spring') yearB += '05'
          else yearB += '60'
          if (yearA > yearB) return -1
          return 1
        })
      })
    })

    const isProcessing = ref(false)
    const updatedCount = ref(0)
    const totalCount = ref(0)
    async function save () {
      const service = root.$feathers.service('chapel/student')
      const aggregate = [
        { $match: { term: props.term, required: { $gte: origRequired.value } } },
        { $project: { _id: 1 } }
      ]
      isProcessing.value = true
      let { data } = await service.find({ query: { aggregate } })
      totalCount.value = data.length
      updatedCount.value = 0
      for (const { _id } of data) {
        try {
          await root.$feathers.service('chapel/student').patch(_id, { required: required.value })
          updatedCount.value++
        } catch (err) {
          alert('Error updating requirement: ' + err)
        }
      }
      emit('updated')
      isProcessing.value = false
      completed.value = true
    }

    const completed = ref(false)

    function close (clearComplete) {
      if (clearComplete) {
        completed.value = false
        totalCount.value = 0
      }
      active.value = false
    }

    return {
      active,
      required,
      origRequired,
      isLoaded,
      chapelCount,
      isProcessing,
      updatedCount,
      totalCount,
      save,
      completed,
      close
    }
  }
}
</script>
